import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {getProductById, getProductRentalPriceByProductId} from "../../utils/requestUtils";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import BackButton from "../common/BackButton";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

const apiUrl = Config.apiUrl;
const ProductManager = () => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        name: '',
        salePrice: 0,
        category: ''
    };
    const [formData, setFormData] = useState(newEntity);


    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : type === 'file' ? e.target.files[0] : value,
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const product = await getProductById(id);
                setFormData(product);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);


    function validateInputs() {

        if (!formData.name || formData.name === '') {
            alert('Preencha o campo nome.');
            return false;
        }

        return true;
    }

    async function saveEntity() {

        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/products';
        const productResponse = await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(formData));

    }


    const onConfirm = async () => {
        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                localStorage.removeItem('products');
                navigate('/productlist');
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function clearEntity() {
        setFormData(newEntity);
    }

    return (
        <div>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Produto</div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome:</div>
                <input id="productName" type="text" style={{width: '300px'}} value={formData.name} name="name"
                       onChange={handleChange}/>
            </div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Categoria:</div>
                <input id="category" type="text" style={{width: '300px'}} value={formData.category} name="category"
                       onChange={handleChange}/>
            </div>
            <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Preço de Venda:</div>
                <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                       value={formData.salePrice}
                       name="salePrice"
                       onChange={handleChange}/>
            </div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Habilitado?:</div>
                <input id="enabled" type="checkbox" checked={formData.enabled} name="enabled"
                       onChange={handleChange}/>
            </div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Ordem:</div>
                <input id="sort" type="number" value={formData.sort} name="sort" step="1"
                       onChange={handleChange}/>
            </div>
            <ActionButtonsManagerView>
                <BackButton onClick={() => navigate(-1)}/>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>
        </div>
    );
};

export default ProductManager;
